import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import * as serviceWorker from './serviceWorker';
import './utilities/i18n'
import config from './config'
import './slick.css'
import './slick-theme.css'
import { Helmet } from 'react-helmet';

let language = localStorage.getItem(`${config.NAME_KEY}:language`)

const Theme1 = React.lazy(() => import('./AppRtl'));
const Theme2 = React.lazy(() => import('./App'));


ReactDOM.render(
      <>
      <React.Suspense fallback={() => null}>
        {language === 'ar' ? <Theme1 /> : <Theme2 />}
      </React.Suspense>
      {config.API_TYPE === 'production'?
      <Helmet>
        <script type="text/javascript">
          {(function(a,b,c,d){
          a='//tags.tiqcdn.com/utag/mdlbeast/main/qa/utag.js';
          b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
          a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
          })()}
        </script>
      </Helmet>
      :
      <Helmet>
      <script type="text/javascript">
        {(function(a,b,c,d){
        a='https://tags.tiqcdn.com/utag/mdlbeast/main/dev/utag.js';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
        })()}
      </script>
      </Helmet>
      }
      </>,
  document.getElementById('root')
);

/* <React.StrictMode>
</React.StrictMode> */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
