const config = {
  NODE_ENV: process.env.NODE_ENV,
  NAME_KEY: process.env.REACT_APP_NAME_KEY || 'App',
  NAME_TITLE: process.env.REACT_APP_NAME_TITLE || 'app',
  DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  DAY: process.env.REACT_APP_DAY || 1,
  TYPE: process.env.REACT_APP_TYPE || 'pre', // pre/post/thankyou
  API_TYPE: process.env.REACT_APP_API_TYPE || 'production', // production/development
  PROTECTION : process.env.REACT_APP_PROTECTED || 'false'
}

export default config